<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >

    <CSidebarBrand class="d-md-down-none">

      <span class="c-sidebar-brand-full display-5">
        <form>
          <select class="form-control form-select" @change="onModuleChange($event)" size="sm" 
              title="Choose Module" v-model="module">
            <!-- <option selected disabled value="Select Module">Select Module</option> -->
            <option v-for="mod in this.$store.state.mods">
              {{ mod }}
            </option>

          </select>
        </form>
      </span>

      <!-- <span class="c-sidebar-brand-minimized display-5">
        Inventory
        <br>
        Order
      </span>  -->

    </CSidebarBrand>

    <div align="center" class="ml-4">
        <label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
        <scale-loader :loading="loading"></scale-loader>
    </div>

    {{userMessage}}

    <!-- <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 556 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand> -->

    <!-- <CRenderFunction flat :content-to-render="$options.nav"/> -->
    <!-- <CRenderFunction :contentToRender="computedSidebar" flat/> -->
    <CRenderFunction :contentToRender="sideBarMenu" flat/>

    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import axios from 'axios'
import ScaleLoader from '@/views/utils/ScaleLoader'

// import nav from './_nav'

const dividerItem = {
  _name: 'CSidebarNavItem',
  name: '',
  icon: 'cil-options',
  to: '#'
}

const dividerItem2 = {
  _name: 'CSidebarNavDivider'
}

const homeItem = {
  _name: 'CSidebarNavItem',
  name: 'Home',
  icon: 'cil-home',
  to: '/'
}

const dashboardItem = {
  _name: 'CSidebarNavItem',
  name: 'Dashboard',
  icon: 'cil-speedometer',
  to: '/IL/dashboard'
}

const qrEntryItem = {
  _name: 'CSidebarNavItem',
  name: 'QR Entry',
  icon: 'cil-qr-code',
  to: '/IL/qrEntry'
}

// const valueSummaryReportItem = {
//         _name: 'CSidebarNavItem',
//         name: 'Inventory Value Report',
//         icon: 'cil-money',
//         to: '/IL/valueSummary',
//       }

const contactItem = {
  _name: 'CSidebarNavItem',
  name: 'Contact SignLedger',
  to: '/admin/contact',
  icon: 'cil-envelope-open'
}

const manualItem = {
  _name: 'CSidebarNavItem',
  name: 'Admin User Manual',
  to: '/admin/usermanual',
  icon: 'cil-info'
}

// POS
// const orderEntryItem = {
//         _name: 'CSidebarNavItem',
//         name: 'Order Entry',
//         icon: 'cil-qr-code',
//         to: '/pos/orderEntry',
//       }

// const dailyOrdersItem = {
//         _name: 'CSidebarNavItem',
//         name: 'Daily Orders',
//         icon: 'cil-qr-code',
//         to: '/pos/dailyOrders',
//       }

const noItems =
[
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Home',
        icon: 'cil-home',
        to: '/'
      },

      {
        _name: 'CSidebarNavItem',
        name: 'Contact SignLedger',
        to: '/admin/contact',
        icon: 'cil-envelope-open'
      }
    ]
  }
]

export default {
  name: 'TheSidebar',

  components: {
    ScaleLoader
  },

  data () {
    return {
      loading: false,
      loadingMsg: null,
      userMessage: '',
      module: '',
      sideBarMenu: [{ _name: 'CSidebarNav', _children: noItems }]
    }
  },

  // nav1,
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },

    minimize () {
      return this.$store.state.sidebarMinimize
    }
  },

  created() {
      this.orgId = this.$route.params.orgId
    },

  mounted () {
    var self = this

    // 'refreshSidebar' : Triggered by - Signin, Signout (from Header)
    this.bus.$on('refreshSidebar', () => {
      self.refreshSidebar()
    })
  },

  methods: {
    onModuleChange(event) {
      this.module = event.target.value
      this.refreshSidebar()
    },

    refreshSidebar () {      
      var menuChildren = []
      if (this.$store.state.mods){
        if (this.$store.state.mods.length > 0 && this.module == ''){
          var host = window.location.host
          var subdomain = host.split('.')[0].toLowerCase()
          if (subdomain=="inventory" && this.$store.state.mods.includes('Inventory Ledger'))
            this.module = 'Inventory Ledger'
          else if (subdomain=="orders" && this.$store.state.mods.includes('Sale Ledger'))
            this.module = 'Sale Ledger'

          //this.module = this.$store.state.mods[0]
        }
      }
      else{
        this.module = ""
      }
      //module: this.$store.state.mods[0],
      if (this.module == 'Inventory Ledger') {
        menuChildren = this.getMenuInventoryLedger() 
      } 
      else if (this.module == 'Sale Ledger') {
        menuChildren = this.getMenuSaleLedger() 
      }
      else if (this.module == 'Purchase Ledger') {
          menuChildren = this.getMenuPurchaseLedger() 
      }
      else if (this.module == 'System Administration') {
          menuChildren = this.getMenuSystemAdmin() 
      }
      else if (this.module == 'Reports') {
          menuChildren = this.getMenuReports() 
      }
      else if (this.module == 'Tax Ledger') {
          menuChildren = this.getMenuTaxLedger() 
      }
      else {
        menuChildren = this.getMenuChildrenBasic() 
      }

      var menuNav = {}
      menuNav._name = 'CSidebarNav'
      menuNav._children = menuChildren

      this.sideBarMenu = [menuNav]
    },

    getMenuChildrenBasic () {
      var menuChildren = []
      //menuChildren.push(homeItem)
      menuChildren.push({ _name: 'CSidebarNavItem', name: 'Home', icon: 'cil-home', to: '/' + this.orgId })
      return menuChildren
    },

    // ****************  Menu for Inventory Ledger ********************
    getMenuInventoryLedger () {
      var menuChildren = []

      i = 0
      var dbItem = {}
      var dashboards = this.$store.state.mod_Inventory['dashboards']
      for (var i = 0; i < dashboards.length; i++) {
        dbItem = {
          _name: 'CSidebarNavItem',
          name: 'Dashboard-' + dashboards[i],
          icon: 'cil-speedometer',
          to: '/' + this.orgId + '/IL/dashboard/' + dashboards[i]
        }
        menuChildren.push(dbItem)
      }

      if (dashboards.length > 0) { 
        menuChildren.push(dividerItem2) 
      }

      // ****************  Location Based - Entry & Reports ********************
      var locations = this.$store.state.mod_Inventory['locations']
      var locEntryItems = []
      var locReportsItems = []

      for (const location of locations){ 
        locEntryItems.push( { name: location, to: '/' + this.orgId + '/IL/locationwiseEntry/' + location } )
        locReportsItems.push( { name: location, to: '/' + this.orgId + '/IL/locationReports/' + location } )
      }

      if (locEntryItems.length > 0) {
        if (this.$store.state.mod_Inventory['roles'].includes('Entry')) {
          var entryItem = {
            _name: 'CSidebarNavDropdown',
            name: 'Locationwise Entry',
            route: '/base',
            icon: 'cil-locationPin',
            items: locEntryItems
          }
          menuChildren.push(entryItem)
        }

        if (this.$store.state.mod_Inventory['roles'].includes('Reports')) {
          var reportsItem = {
            _name: 'CSidebarNavDropdown',
            name: 'Locationwise Reports',
            route: '/base',
            icon: 'cil-locationPin',
            items: locReportsItems
          }
          menuChildren.push(reportsItem)
        }

        // Divider
        menuChildren.push(dividerItem2)
      }

      // ****************  Item Based - Entry & Reports ********************

      // Build menu based on item1s for this admin user
      var items = this.$store.state.mod_Inventory['items']
      var itemEntryItems = []
      var itemReportsItems = []
      for (const item of items) {
        itemEntryItems.push({ name: item, to: '/' + this.orgId + '/IL/itemwiseEntry/' + item })
        itemReportsItems.push({ name: item, to: '/' + this.orgId + '/IL/itemReports/' + item })
      }
      // If items are assigned to this admin user
      if (itemEntryItems.length > 0) {
        if (this.$store.state.mod_Inventory['roles'].includes('Entry')) {
          var entryItem = {
            _name: 'CSidebarNavDropdown',
            name: 'Itemwise Entry',
            route: '/base',
            icon: 'cil-list',
            items: itemEntryItems
          }
          menuChildren.push(entryItem)
        }

        if (this.$store.state.mod_Inventory['roles'].includes('Reports')) {
          var reportsItem = {
            _name: 'CSidebarNavDropdown',
            name: 'Itemwise Reports',
            route: '/base',
            icon: 'cil-list',
            items: itemReportsItems
          }
          menuChildren.push(reportsItem)
        }

        // Divider
        menuChildren.push(dividerItem2)
      }

      return menuChildren
    },

    // ****************  Menu for Sale Ledger ********************
    getMenuSaleLedger () {
      var menuChildren = []

      // Build menu based on orderLocations for this admin user
      var locations = this.$store.state.mod_SaleOrder['locations']
      for (const location of locations) {
        menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-Truck',
                           name: location.name + ' Orders', to: '/' + this.orgId + '/orders/orders/Sale/Entry/' + location.name + '/' + location.type })
      }

      // Divider
      menuChildren.push(dividerItem2)
      
      if (this.$store.state.mod_SaleOrder['roles'].includes('Entry')) {
        menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-Cursor',
                           name: 'Orders - For Approval', to: '/' + this.orgId + '/orders/orders/Sale/Approval/All/standard'  })
      }
      if (this.$store.state.mod_SaleOrder['roles'].includes('Reports')) {
        menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-flight-takeoff',
                           name: 'Orders - For Dispatch', to: '/' + this.orgId + '/orders/orders/Sale/Dispatch/All/standard' })
      }

      // Divider
      menuChildren.push(dividerItem2)

      if (this.$store.state.mod_SaleOrder['roles'].includes('Entry')) {
        menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-Bell',
                           name: 'Messages', to: '/' + this.orgId + '/orders/messages'  })
      }

      // Divider
      menuChildren.push(dividerItem2)

      if (this.$store.state.mod_SaleOrder['roles'].includes('Entry')) {
          menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-User',
                            name: 'Customers', to: '/' + this.orgId + '/admin/partners/Customer' })
          menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-User',
                            name: 'Transporters', to: '/' + this.orgId + '/admin/partners/Transporter' })
      }

      // Divider
      menuChildren.push(dividerItem2)

      return menuChildren
    },

    getMenuPurchaseLedger () {
      var menuChildren = []

      // Build menu based on orderLocations for this admin user
      var locations = this.$store.state.mod_PurchaseOrder['locations']
      for (const location of locations) {
        menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-Truck',
                          name: location.name + ' Orders', to: '/' + this.orgId + '/orders/orders/Purchase/Entry/' + location.name + '/' + location.type })
      }

      // Divider
      // menuChildren.push(dividerItem2)

      // if (this.$store.state.mod_PurchaseOrder['roles'].includes('Entry')) {
      //   menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-Cursor',
      //                     name: 'Orders - Waiting Approval', to: '/orders/orders/Purchase/Approve/All' })
      // }
      // if (this.$store.state.mod_PurchaseOrder['roles'].includes('Reports')) {
      //   menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-flight-takeoff',
      //                     name: 'Orders - Ready for Dispatch', to: '/orders/orders/Purchase/Dispatch/All' })
      // }
      // if (this.$store.state.mod_PurchaseOrder['roles'].includes('Entry')) {
      //   menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-flight-takeoff',
      //                     name: 'Outstanding Payments', to: '/orders/outstandingPayments/Purchase' })
      // }

      // Divider
      menuChildren.push(dividerItem2)

      if (this.$store.state.mod_PurchaseOrder['roles'].includes('Entry')) {
          menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-User',
                            name: 'Suppliers', to: '/' + this.orgId + '/admin/partners/Supplier' })
      }

      // Divider
      menuChildren.push(dividerItem2)


      return menuChildren

    },

    // ****************  Module - Reports ********************
    getMenuReports () {
      var menuChildren = []

      if (this.$store.state.mod_Inventory['roles'].includes('Reports')) {
        menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-file',
                                  name: 'Sale - Amounts', to: '/' + this.orgId + '/orders/SaleAmtReport/Sale' })

        menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-file',
                                  name: 'Sale - Quantity', to: '/' + this.orgId + '/orders/SaleQtyReport/Sale' })
        
        menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-file',
                                  name: 'Manufacturing', to: '/' + this.orgId + '/IL/customReports/Manufacturing/Inventory' })

        menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-file',
                                  name: 'Inventory Value', to: '/' + this.orgId + '/IL/valueSummary' })

        menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-file',
                                  name: 'Customer Order Volume', to: '/' + this.orgId + '/orders/customerOrderVolume' })
        
        menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-file',
                                  name: 'Purchase - Amounts', to: '/' + this.orgId + '/orders/SaleAmtReport/Purchase' })

        menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-file',
                                  name: 'Purchase - Quantity', to: '/' + this.orgId + '/orders/SaleQtyReport/Purchase' })

        // menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-file',
        //                           name: 'Sale CashFlow', to: '/orders/orderValueReport/Sale/CashFlow' })

        // menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-file',
        //                           name: 'Purchase CashFlow', to: '/orders/orderValueReport/Purchase/CashFlow' })
      }

      // Divider
      // menuChildren.push(dividerItem2)
      // if (this.$store.state.mod_SaleOrder['roles'].includes('Reports')) {
      //     menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-file',
      //                       name: 'GSTR1 Sales Report', to: '/orders/GSTR1/Sale/GSTR1Sales' })
      // }
      
      // Divider
      menuChildren.push(dividerItem2)

      return menuChildren

    },

    getMenuSystemAdmin () {
      var menuChildren = []

      if (this.$store.state.mod_SystemAdmin['roles'].includes('Super')) {
          menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-home',
                    name: 'Organization', to: '/' + this.orgId + '/admin/org' })
          menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-list',
                            name: 'Items', to: '/' + this.orgId + '/admin/item1s' })
          menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-locationPin',
                            name: 'Locations', to: '/' + this.orgId + '/admin/locations' })

          menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-speedometer',
                            name: 'Dashboard-1', to: '/' + this.orgId + '/admin/dashboardCrud/1' })
          menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-speedometer',
                            name: 'Dashboard-2', to: '/' + this.orgId + '/admin/dashboardCrud/2' })

          var reportsItems = []
          reportsItems.push({ name: 'Sale - Amount', to: '/' + this.orgId + '/admin/customReportsMaint/Sale - Amount' })
          reportsItems.push({ name: 'Sale - Quantity', to: '/' + this.orgId + '/admin/customReportsMaint/Sale - Quantity' })
          reportsItems.push({ name: 'Manufacturing', to: '/' + this.orgId + '/admin/customReportsMaint/Manufacturing' })
          reportsItems.push({ name: 'valueSummary', to: '/' + this.orgId + '/admin/customReportsMaint/ValueSummary' })

          var reportsItem = {
            _name: 'CSidebarNavDropdown',
            name: 'Org Reports',
            route: '/base',
            icon: 'cil-file',
            items: reportsItems
          }

          menuChildren.push(reportsItem)

          menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-user',
                            name: 'Admin Users', to: '/' + this.orgId + '/admin/adminUserCrud' })

      }

      // Divider
      menuChildren.push(dividerItem2)

      return menuChildren

    },

    // ****************  Module - Tax Ledger ********************
    getMenuTaxLedger () {
      var menuChildren = []

      if (this.$store.state.mod_SaleOrder['roles'].includes('Reports')) {
        menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-file',
                                  name: 'GSTR-1', to: '/' + this.orgId + '/taxes/GSTR/Sale/GSTR-1' })
        
        // menuChildren.push({_name: 'CSidebarNavItem', icon: 'cil-file',
        //                           name: 'GSTR-3B', to: '/' + this.orgId + '/taxes/GSTR/Sale/GSTR-3B' })
      }
      
      // Divider
      menuChildren.push(dividerItem2)

      return menuChildren
    },

  // End of 'methods'
  }

// End of 'export default'
}

</script>
