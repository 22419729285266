<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://www.signledger.com" target="_blank">SignLedger LLC</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} </span>
    </div>

    <div class="mfs-auto">
      <!-- <span>Solutions for SMB (Small & Midsize Business)</span> -->
      {{ onlineStatus }}
    </div>

    <!-- <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://coreui.io/vue">CoreUI for Vue</a>
    </div> -->
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',

  data () {
    return {
      // check internet status
      onLine: navigator.onLine,
      showBackOnline: false,
      onlineStatus: 'OnLine',
    }
  },

  mounted () {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },

  beforeDestroy() {
      window.removeEventListener('online', this.updateOnlineStatus);
      window.removeEventListener('offline', this.updateOnlineStatus);
  },

  watch: {
      onLine(v) {
          if (v) {
              this.showBackOnline = true;
              setTimeout(() => {
                  this.showBackOnline = false;
              }, 1000);
          }
      }
  },

  methods: {
    updateOnlineStatus(e) {
        const {
            type
        } = e;
        //this.onLine = type === 'online'
        if (type=='online'){
          this.onlineStatus = 'OnLine'
        }
        else {
          this.onlineStatus = 'OffLine'
        }
    },
  },

}
</script>
