


<template>
  <CHeader>
    <div class="container">

      <div class="row text-white bg-dark">
          <div class="col-sm-1 text-left">
              <CToggler
                in-header
                class="ml-3 text-white d-lg-none"
                @click="$store.commit('toggleSidebarMobile')"
              />
              <CToggler
                in-header
                class="ml-3 text-white d-md-down-none"
                @click="$store.commit('toggleSidebarDesktop')"
              />
          </div>

          <div v-if="$store.state.isLoggedIn == true" class="col-sm-8 text-left">
              User: {{$store.state.user.email}}
              <br>
              <a href='#' style="color: white" v-on:click='logOut()'><u>Logout</u></a>
          </div>
          <div v-else class="col-sm-8 text-left">

              <div style="cursor: pointer;">
                <img src="../../public/btn_google_signin_dark_normal_web.png" v-on:click.prevent="userGoogleLogin()">
              </div>
              <!-- <router-link to="/auth/signin" style="color: white"><u>Sign in</u></router-link> -->
          </div>

          <div class="col-sm-3 text-right">
              {{this.$store.state.orgName}}
              <img src="/SLi_64x64.png" class="img-fluid ml-3" alt="Responsive image">
          </div>
      </div>

      <div class="row text-white bg-dark">
        <div class="col-sm-12 text-center">
        <!-- <div class="mfs-auto"> -->
            <a style="color: white" v-if="isRefresh" href="#" @click="update()">Click here to update to New Version</a>
        </div>
      </div>

    </div>

  </CHeader>
</template>

<script>
import Vue from 'vue'
import { firebaseAuth } from '../firebaseConfig.js';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth"

import { CHeader } from '@coreui/vue'

import axios from 'axios'
import ScaleLoader from '@/views/utils/ScaleLoader'



export default {
  name: 'TheHeader',

  components: {
    ScaleLoader,
    // TheHeaderDropdownAccnt,
  },

  data () {
    return {
      orgId: null,
      userLoggedIn: false,
      userName: '',
      orgName: '',

      // Refresh PWA
      registration:null,
      isRefresh: false,
      refreshing: false,
    }
  },

  beforeMount: function () {
  },

  mounted () {
    var self = this

    self.bus.$emit('refreshSidebar')

    // 'refreshHeader' : Triggered by - Signin page
    // this.bus.$on('refreshHeader', () => {
    //   self.refreshHeader()
    // })
  },

  // Refresh PWA
  created() {
      this.orgId = this.$route.params.orgId
      document.addEventListener(
          'serviceWorkerUpdateEvent', this.appUpdateUI, { once: true }
      )
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // We'll also need to add 'refreshing' to our data originally set to false.
        if (this.refreshing) return
        this.refreshing = true
        // Here the actual reload of the page occurs
        window.location.reload()
      })
  },


   methods: {
    // refreshHeader () {
    //   var self = this
    //   firebase.auth().onAuthStateChanged(function (user) {
    //     if (user) {
    //       self.userLoggedIn = true
    //       self.userName = user.email
    //       self.bus.$emit('refreshSidebar')
    //     } else {
    //       self.userLoggedIn = false
    //       self.userName = ''
    //       // Clear User Profile from Session State
    //       self.saveUserProfile('', '', '', '', '', false, '', '', '', '', '').then(function (response) {
    //               self.bus.$emit('refreshSidebar')
    //       })
    //     }
    //   })
    // },

    logOut () {
      var self = this
      //firebase.auth().signOut()
      firebaseAuth.signOut()
        .then(function () {
          self.$store.commit('authStatusChange')
          self.saveUserProfile('', '', '', '', '', false, '', '', '', '', '').then(function (response) {
                  self.bus.$emit('refreshSidebar')
          })
        })
        .catch(function (error) {
          alert('Signout unsuccessful: ' + error)
        })
    },

    // ***** Refresh PWA
    appUpdateUI:function (e){
      this.registration = e.detail;
      this.isRefresh = true;
    },

    update(){
      this.isRefresh = false;
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({type:'SKIP_WAITING'});
      }
    },
    // ***** Refresh PWA

    userGoogleLogin(){
        var self = this

        const provider = new GoogleAuthProvider()
        provider.setCustomParameters({
            prompt: 'select_account'
        })

        signInWithPopup(firebaseAuth, provider)
          .then(() => {
            self.$store.commit('authStatusChange')
            self.serverCall("getUserPermissions")
          }).catch((error) => {
            self.$alert(String(error.message))
          });

        
        // //const auth = getAuth()
        // var provider = new firebase.auth.GoogleAuthProvider()
        // provider.setCustomParameters({
        //   prompt: 'select_account'
        // })

        // firebase.auth()
        //   .signInWithPopup(provider)
        //   .then((result) => {
        //       /** @type {firebase.auth.OAuthCredential} */
        //       var credential = result.credential
        //       // This gives you a Google Access Token. You can use it to access the Google API.
        //       var googleToken = credential.idToken

        //       // The signed-in user info.
        //       const user = result.user.email
        //       if (user) {
        //           self.serverCall("getUserPermissions")
        //       }
        //       else {
        //           self.userLoggedIn = false
        //           self.userName = ''
        //       }

        //   }).catch((error) => {
        //     // Handle Errors here.
        //     const errorCode = error.code;
        //     const errorMessage = error.message;
        //     // The email of the user's account used.
        //     //const email = error.email;
        //     // The AuthCredential type that was used.
        //     //const credential = GoogleAuthProvider.credentialFromError(error);
        //     self.$alert(String(errorMessage))
        //   });
    },

    serverCall (name) {
      var self = this

        firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
            switch (name) {
              case 'getUserPermissions':
                self.getUserPermissions(idToken)
                break

              default:
                console.log('Unknown function: ' + name)
            }
          })
        } else {
          self.updateResultMsg = 'User logged out. Please login.'
        }
      })
    },
          
    // Get user permissions using token issued by authentication
    getUserPermissions(token){
        var self = this
        self.loading = true
        self.loadingMsg = 'Getting User Permissions...'
        // Check if the user-org combination exists in the backend
        axios({
          method: 'POST',
          baseURL: process.env.VUE_APP_ROOT_API,
          url: '/GetUserOrg/',
          headers: {
            'Authorization': 'Bearer ' + token,
            'OrgId': this.orgId,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(function (response) {
          // Reset loading message
          self.loading = false
          self.loadingMsg = null

          if (response.data.err != '') {
            console.log('Error:' + response.data.err)
            self.$alert(String(response.data.err))
            self.saveUserProfile('', '', '', '', '', false, '', '', '', '', '').then(function (response) {
                  self.bus.$emit('refreshSidebar')
            })
          } else {
              self.saveUserProfile(
                response.data.org,
                response.data.orgName,
                response.data.orgState, 
                response.data.customReports,
                response.data.taxes,
                response.data.betaTest,
                response.data.mods, 
                response.data.mod_Inventory, 
                response.data.mod_SaleOrder, 
                response.data.mod_PurchaseOrder, 
                response.data.mod_SystemAdmin
              ).then(function (response) {
                  self.bus.$emit('refreshSidebar')
                  // Send user to Home page
                  self.$router.push('/' + self.orgId)
              })
          }
        })
        .catch(function (error) {
            // Reset loading message
            self.loading = false
            self.loadingMsg = null
            console.log(error)
            self.$alert(String(error))
            self.saveUserProfile('', '', '', '', '', false, '', '', '', '', '').then(function (response) {
                  self.bus.$emit('refreshSidebar')
            })
        })
        
    },

  }

}
</script>
